import cx from 'classnames'
import { Listbox } from '@headlessui/react'
import React, { useMemo, useCallback, useState } from 'react'
import { Field, useField, useFieldState } from 'formular'

import { useLimitedScroll, useUniqueId } from 'hooks'

import { Icon, IconName, Text } from 'components/dataDisplay'

import Input from '../Input/Input'

import s from './Dropdown.scss'
import ops from './Option.scss' // TODO rework this


export type DropdownProps = {
  className?: string
  // value: DropdownOption
  // onChange: Dispatch<any>
  field: Field<string | number>
  searchField?: Field<string>
  options: DropdownOption[]
  label: string
  icon?: IconName
  optionIcon?: IconName
  disabled?: boolean
  withSearch?: boolean
  position?: 'top'
  withoutValue?: boolean
  withoutButton?: boolean
  onChange?: (option: DropdownOption) => void
}

export type DropdownOption = {
  value: number | string
  title: string
  subTitle?: string
  unavailable?: boolean
  //count?: number
  className?: string
  icon?: IconName
}
// TODO implement option icon
const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    className,
    field,
    options,
    label,
    icon,
    searchField: propsSearchField,
    disabled = false,
    withSearch = false,
    withoutValue = false,
    position,
    withoutButton,
    onChange,
  } = props

  const { value, error } = useFieldState<number | string>(field)

  const controlId = useUniqueId('div-')

  const handleChange = useCallback((option: DropdownOption) => {
    field.set(option.value)

    if (typeof onChange === 'function') {
      onChange(option)
    }

    field.validate()
  }, [ field, onChange ])

  const currentOption = useMemo(
    () => withoutValue ? null : options.find((option) => option.value === value),
    [ options, value, withoutValue ])

  const searchField = useField<string>()

  const { value: searchValue } = useFieldState(searchField)

  const filteredOptions: DropdownOption[] = useMemo(() => {
    if (!searchValue) {
      return options
    }

    const search = searchValue.toLowerCase()

    return options
      .filter(({ title, subTitle }) => {
        const searchArray = search.trim().split(' ')
        const searchRegExp = new RegExp(searchArray.join('.*'), 'g')
        const isTitleMatch = title?.toLowerCase()?.match(searchRegExp)
        const isSubTitleMatch = subTitle?.toLowerCase()?.match(searchRegExp)

        return isTitleMatch || isSubTitleMatch
      })
  }, [ options, searchValue ])

  const isErrored = Boolean(error)

  const [ isOpen, setOpen ] = useState(false)

  const { lastIndex } = useLimitedScroll({
    count: filteredOptions?.length || 0,
    limit: 100,
    elementSelector: isOpen ? '.dropdown-options' : undefined,
  })

  const slicedOptions = useMemo(() => {
    return filteredOptions.slice(0, lastIndex)
  }, [ filteredOptions, lastIndex ])

  return (
    <Listbox
      className={cx(className, 'min-w-1')}
      as="div"
      value={currentOption}
      onChange={handleChange}
      disabled={disabled}
      onKeyDownCapture={(event) => {
        if (withSearch && event.code === 'Space') {
          event.stopPropagation() // ListBox by default select focused option on 'space' key press
          // when we have a Dropdown component with search string we dont need ListBox to behave like this
          // because we need to be able to type spaces in our search string
        }
      }}
    >
      {
        ({ open, disabled }) => {
          setOpen(open)

          return (
            <>
              <Listbox.Button className="flex-1" as="div">
                <div
                  className={cx(s.textArea,
                    'flex radius-8 items-center', {
                      [s.focused]: open,
                      [s.filled]: currentOption !== null && currentOption !== undefined,
                      [s.disabled]: disabled,
                      [s.errored]: isErrored,
                      'hidden': withoutButton,
                    })
                  }
                >
                  {
                    Boolean(icon) && (
                      <Icon
                        className={cx(s.icon, 'my-12px')}
                        name={icon}
                      />
                    )
                  }
                  <div
                    className={cx(s.textContent, 'inline-flex flex-col flex-1 justify-center relative overflow-hidden', {
                      ['ml-8px']: Boolean(icon),
                    })}
                  >
                    <label className={cx(s.label, 'absolute')} htmlFor={controlId}>
                      {label}
                    </label>
                    <Text
                      className={cx(s.text, 'mt-16px whitespace-nowrap overflow-ellipsis')}
                      color="titanic"
                      size="c16"
                      id={controlId}
                      message={currentOption?.title}
                      title={currentOption?.title || ''}
                    />
                  </div>
                  <Icon
                    className={cx(s.arrow, 'my-12px ml-4px')}
                    name={open ? 'arrow/arrow-up_16' : 'arrow/arrow-down_16'}
                    preload={open ? 'arrow/arrow-down_16' : 'arrow/arrow-up_16'}
                  />
                </div>
                {
                  isErrored && (
                    <div className={cx(s.error, 'absolute')}>
                      {error}
                    </div>
                  )
                }
              </Listbox.Button>
              <Listbox.Options className="relative block" as="div">
                <div
                  className={cx(ops.options, 'absolute radius-8 dropdown-options', {
                    [ops.top]: position === 'top',
                    [ops.withoutButton]: withoutButton,
                  })}
                >
                  {
                    withSearch && (
                      <Input
                        className="pl-4px"
                        field={propsSearchField || searchField}
                        label="Поиск"
                        icon="main/search_16"
                        style="dark"
                        autoFocus
                      />
                    )
                  }
                  {
                    slicedOptions.map((option, index, array) => {
                      const {className, value, unavailable = false, title, subTitle, icon} = option

                      return (
                        <Listbox.Option
                          key={value}
                          as="div"
                          value={option}
                          disabled={unavailable}
                        >
                          {
                            ({active, selected, disabled}) => (
                              <div
                                className={cx(className, ops.option, 'flex', {
                                  [ops.disabled]: unavailable,
                                })}
                              >
                                <div
                                  className={cx(ops.indicator, {
                                    [ops.first]: index === 0,
                                    [ops.last]: index === array.length - 1,
                                  })}
                                />
                                <div
                                  className={cx(ops.text, 'flex flex-col')}
                                >
                                  {
                                    Boolean(icon) ? (
                                      <div
                                        className={cx(ops.title, 'flex ml-16px', {
                                          'my-12px': !subTitle,
                                          'mt-4px': subTitle,
                                        })}
                                      >
                                        <Icon
                                          name={icon}
                                          color="rocky"
                                        />
                                        <Text
                                          className="overflow-ellipsis whitespace-nowrap ml-12px"
                                          message={title}
                                          size="c16"
                                          title={title}
                                          color="titanic"
                                        />
                                      </div>
                                    ) : (
                                      <Text
                                        className={cx(ops.title, 'ml-16px overflow-ellipsis whitespace-nowrap', {
                                          'my-12px': !subTitle,
                                          'mt-4px': subTitle,
                                        })}
                                        message={title}
                                        size="c16"
                                        title={title}
                                        color="titanic"
                                      />
                                    )
                                  }
                                  {
                                    subTitle && (
                                      <Text
                                        className={cx(ops.subTitle, 'mb-4px ml-16px')}
                                        message={subTitle}
                                        size="c13"
                                        color="titanic"
                                      />
                                    )
                                  }
                                </div>
                              </div>
                            )
                          }
                        </Listbox.Option>
                      )
                    })
                  }
                </div>
              </Listbox.Options>
            </>
          )
        }
      }
    </Listbox>
  )
}


export default Dropdown
