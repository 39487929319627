import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { Field, FieldState } from 'formular'
import { FileDrop } from 'react-file-drop'
import { getUrl } from 'hooks/query/util'

import { openSubmitFailedModal } from 'compositions/modals'
import { Attachment, IconWithText, FilePreviewAttachment, Text } from 'components/dataDisplay'

import { useUpload } from './util'

import s from './Uploader.scss'


export type UploaderProps = {
  id?: string
  className?: string
  filesField: Field<string[]>
  title?: string
  message?: string
  maxFiles?: number
  acceptTypes?: ('image' | 'docs' | 'video' | 'sheets' | 'pos')[]
  orderId: number | 'direct' // backend constant; different for each layer
  isUploadForImport?: boolean // this is for the case when we uploading file template for universal import(see <ImportFromFileModal/>) // TODO rework for formDataProps object
  withFilePreview?: boolean
  multipleFiles?: boolean
  isHover?: boolean
  onLoad?: (data: any) => void
  renameShort?: number
  subdir?: string
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => {
      reject(error)
    }
  })
}

const mimeTypes = new Map([
  [ 'image', 'image/jpeg, image/png' ],
  [ 'docs', `application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/pdf` ],
  [ 'video', 'video/mp4' ],
  [ 'sheets', `application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  text/csv` ],
  [ 'pos', `txt, odt, ods, odp, xls, xlsx, ppt, pptx, doc, docx, rtf, pdf, jpeg, jpg, pjpeg, bmp, png, tiff, gif, pxx, p7s, sign, sig` ]
])

const Uploader: React.FC<UploaderProps> = (props) => {
  const {
    id,
    className,
    filesField,
    title = 'Прикрепите файлы',
    message = 'Перетяните сюда до 5 файлов, общим объёмом не более 50 МБайт',
    maxFiles = 5,
    acceptTypes,
    orderId,
    isUploadForImport,
    withFilePreview = true,
    multipleFiles,
    onLoad,
    renameShort,
    subdir,
  } = props

  const { uploadFile, deleteFile, isDeleting, isUploading } = useUpload()

  const [ isHover, setIsHover ] = useState(false)

  const fileInputRef = useRef(null)

  const allowedMimes = useMemo(() => (
    acceptTypes?.reduce((mimes, type) => {
      const mimesString = mimeTypes.get(type)

      if (mimesString) {
        return mimes.concat(mimesString.split(', '))
      }

      return mimes
    }, [])
  ), [ acceptTypes ])

  const isHoverTrue = useCallback(()=>{
    setIsHover(true)
  }, [ isHover ])
  const isHoverFalse = useCallback(()=>{
    setIsHover(false)
  }, [ isHover ])

  const handleFileInputChange = useCallback(async (inputFiles: FileList, event) => {
    let allowedFiles = Array.from(inputFiles).filter((file) => {
      const isAllowed = allowedMimes?.includes(file.type) || true

      if (!isAllowed) {
        console.warn(`${file.type} not allowed. File filtered`, allowedMimes)
      }

      return isAllowed
    })

    if (allowedFiles.length > maxFiles) {
      console.warn(`Maximum ${maxFiles} allowed. Rest files have been filtered`, allowedFiles.length)

      allowedFiles = allowedFiles.slice(0, maxFiles)
    }

    const uploadFormData = new FormData()

    uploadFormData.append('orderId', String(orderId))
    allowedFiles.forEach((file) => uploadFormData.append('files[]', file))
    if (isUploadForImport) {
      uploadFormData.append('upload_url', 'import')
      uploadFormData.append('replaceFile', '1')
    }

    // uploadFormData.append('renameShort', '0')

    if (renameShort) {
      uploadFormData.append('renameShort', String(renameShort))
    }
    if (subdir) {
      uploadFormData.append('subdir', subdir)
    }

    const { data } = await uploadFile({
      body: uploadFormData,
    })

    const { files: uploadedFiles } = data

    const fileErrors = uploadedFiles.filter((file) => file?.error)

    const filesWithoutError = uploadedFiles.filter((file) => {
      const isWithError = file?.error

      if (isWithError) {
        filesField.setError(`Ошибка загрузки: ${file.error}`)
      }

      return !isWithError
    })

    if (fileErrors.length) {
      const errors = fileErrors.map((file) => file.url).join('<br />')

      openSubmitFailedModal({
        title: 'Ошибка',
        subTitle: `${fileErrors.length} из ${uploadedFiles.length} файлов не были загружены`,
        text: `<span class="color-fargo">${errors}</span>`,
        textHtml: true,
        buttonTitle: 'Понятно',
      })
    }

    if (filesWithoutError.length === uploadedFiles.length && filesField.state.error) {
      filesField.setError(null)
    }

    filesWithoutError.forEach((file) => {
      if (file.url) {
        const decodedUrl = decodeURI(file.url)

        filesField.set(filesField.state.value ? filesField.state.value.concat(decodedUrl) : [ decodedUrl ])

        if (onLoad) {
          onLoad(file)
        }
      }
    })

  }, [ allowedMimes, maxFiles, orderId, isUploadForImport, uploadFile, filesField, onLoad, renameShort, subdir ])

  const handleFileDelete = useCallback(async (index) => {
    const fileName = filesField.state.value.find((file, fileIndex) => fileIndex === index)
    const updatedFileName = fileName?.replace(/.+\//, '')

    const { data } = await deleteFile({
      url: `/uploadfile/index.php?file=${encodeURI(updatedFileName)}&_method=DELETE&orderId=${String(orderId)}`,
    })

    const newState = filesField.state.value.filter((file, fileIndex) => fileIndex !== index)

    filesField.set(newState)
  }, [ filesField, orderId, deleteFile ])

  const accept = acceptTypes ? allowedMimes.join(', ') : undefined

  const [ isUploaded, setUploaded ] = useState(false)

  useEffect(() => {
    if (isUploading) {
      return () => {
        setUploaded(true)

        setTimeout(() => setUploaded(false), 525)
      }
    }
  }, [ isUploading ])

  return (
    <div id={id}>
      <FileDrop
        className={cx(/*s.container,*/ className, ' text-center radius-8 relative overflow-hidden', {
          [s.uploading]: isUploading,
          [s.uploaded]: isUploaded,
        })}
        onTargetClick={() => fileInputRef.current.click()}
        onDrop={handleFileInputChange}
      >
        <div
          className={cx(s.container, 'px-16px py-24px cursor-pointer radius-8')}
          onMouseEnter={isHoverTrue}
          onMouseLeave={isHoverFalse}
        >
          <div className={cx(s.status, 'absolute top- left-0 h-full opacity-16')} />
          <input
            ref={fileInputRef}
            className="hidden"
            type="file"
            accept={accept}
            multiple={multipleFiles}
            onChange={(event) => handleFileInputChange(event.target.files, event)}
          />
          <IconWithText
            className="pointer justify-center"
            message={isUploading || isUploaded ? 'Загрузка...' : title}
            iconName="action/attach_16"
            color={isHover ? 'godfather' : 'titanic'}
            textProps={{
              size: 'c16',
            }}
          />
          <Text
            className={cx('mt-4px pointer', isHover ? 'opacity-72' : 'opacity-48')}
            message={message}
            size="c13"
            color={isHover ? 'godfather' : 'titanic'}
          />
        </div>
      </FileDrop>
      <FieldState field={filesField}>
        {
          ({ value, error }) => (
            <div
              className={cx('flex flex-col', {
                'mt-24px': Boolean(value.length),
              })}
            >
              {
                Boolean(error) && (
                  <Text
                    className="mt-8px"
                    message={error}
                    size="t16-20"
                    color="fargo"
                  />
                )
              }
              {
                Boolean(value.length) && (
                  value.map((filePath, index) => {
                    // TODO check do we need || filePath?
                    console.log({ filePath, value })

                    const name = filePath.replace(/.*\//, '').replace(/\..*/, '')
                    const type = filePath.replace(/.*\./, '')
                    const url = `${filePath}`
                    const isImage = /(jpe?g|png)/.test(type)

                    if (withFilePreview) {
                      return (
                        <FilePreviewAttachment
                          className={index ? 'mt-16px' : null}
                          isImage={isImage}
                          url={url}
                          title={name}
                          onDeleteClick={() => handleFileDelete(index)}
                        />
                      )
                    }

                    return (
                      <Attachment
                        key={filePath}
                        className={index ? 'mt-16px' : null}
                        title={name}
                        icon={
                          /\.(docx?|pdf)$/.test(type)
                            ? 'file/file_16'
                            : 'action/attach_16'
                        }
                        to={getUrl(url)}
                        onDeleteClick={() => handleFileDelete(index)}
                      />
                    )
                  })
                )
              }
            </div>
          )
        }
      </FieldState>
    </div>
  )
}


export default Uploader
