import React, { forwardRef, useRef, useEffect, ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { useUniqueId } from 'hooks'
import { useDevice } from 'device'
import logger from 'logger'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'

import createTooltip from './util/createTooltip'

import s from './Tooltip.scss'


// TODO add styles for positions
export type TooltipPosition = 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left'

type TooltipWrapperProps = {
  children?: ReactElement
  message: string
  html?: boolean
  position: TooltipPosition
  size?: 'small' | 'big'
}

type TooltipProps = TooltipWrapperProps & {
  id: string
}

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(({ id, message, html, size = 'big', position = 'top-left' }, ref) => createPortal(
  <div
    ref={ref}
    id={id}
    className={cx(s.tooltip, 'radius-16 shadow-medium-titanic', s[position], {
      'p-16px': size === 'big',
      'px-8px py-4px': size === 'small',
    })}
    role="tooltip"
  >
    <Text message={message} size="s13-r" html={html} />
  </div>,
  document?.getElementById('tooltips')
))

const TooltipWrapper: React.FC<TooltipWrapperProps> = (props) => {
  const { children, message, html, position, size } = props
  const { isMobile } = useDevice()

  const id = useUniqueId('tooltip')
  const triggerRef = useRef<HTMLElement>()
  const tooltipRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!triggerRef.current) {
      logger.error('triggerRef for Tooltip is undefined', {
        extra: {
          children,
          message,
        },
      })

      return
    }

    return createTooltip({
      trigger: triggerRef.current,
      tooltip: tooltipRef.current,
      styles: s,
      isMobile,
      position,
    })
  }, [ children, message, position, isMobile ])

  if (__SERVER__ || !message) {
    return (
      React.cloneElement(children, {
        ref: triggerRef,
        ariaDescribedby: id,
      })
    )
  }

  return (
    <>
      <Tooltip
        ref={tooltipRef}
        id={id}
        message={message}
        html={html}
        size={size}
        position={position}
      />
      {
        React.cloneElement(children, {
          ref: triggerRef,
          ariaDescribedby: id,
        })
      }
    </>
  )
}


export default React.memo(TooltipWrapper)
