import config from 'config'
import cx from 'classnames'
import { useZinaImage } from 'zina'
import React, { useMemo } from 'react'

import loaderImage from './images/spinner.svg'

import s from './Image.scss'


export type ImageProps = {
  className?: string
  src: string
  fallback?: string | (() => void)
  bgColor?: string
  noResize?: boolean
  aspect?: number
  withLoader?: boolean
  alt?: string
  title?: string
  fullWidth?: boolean
  width?: string
  height?: string
  tag?: string
  style?: React.CSSProperties
  onLoad?: () => void
}

const Image: React.FC<ImageProps> = (props) => {
  const {
    className, src, fallback, aspect, withLoader = true,
    alt = '', title, fullWidth, width: initialWidth, height: initialHeight, tag, onLoad, noResize = false, style,
  } = props

  const width = initialWidth ? Math.round(Number(initialWidth)).toString() : initialWidth
  const height = initialHeight ? Math.round(Number(initialHeight)).toString() : initialHeight

  const skip = /base64|\.svg$/.test(src) ? true : noResize

  const imageAspect = useMemo(() => (
    width && height ? Number(width) / Number(height) : aspect
  ), [ width, height, aspect ])

  const calculatedHeight = useMemo(() => (!height && width && imageAspect) ? String(parseInt(width) / imageAspect) : null, [ height, imageAspect, width ])
  const { nodeRef, zinaSrc, isLoading } = useZinaImage({
    src: __DEV__ ? 'https://' + config.appDomain + src : src,
    fallback,
    aspect: imageAspect,
    withLoader,
    height: height || calculatedHeight,
    onLoad,
    skip,
    // additionalQuery: width && height ? '&refresh=1' : '',
  })

  const nodeClassName = cx(className, {
    [s.loader]: isLoading && (!skip || noResize),
    'w-full': fullWidth,
  })

  if (tag === 'div') {
    return (
      <div
        className={nodeClassName}
        ref={nodeRef}
        style={{
          backgroundImage: isLoading
            ? `url(${loaderImage})`
            : `url(${skip ? src : zinaSrc}`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          height: `${height || calculatedHeight}px`,
          minWidth: `${width}px`,
          ...style,
        }}
      />
    )
  }

  return (
    <img
      className={nodeClassName}
      style={{
        backgroundImage: isLoading ? `url(${loaderImage})` : undefined,
        ...style,
      }}
      ref={nodeRef}
      src={skip ? src : zinaSrc}
      alt={alt}
      title={title}
      width={width}
      height={height || calculatedHeight}
    />
  )
}

export default React.memo(Image)
